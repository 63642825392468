<template>
    <div class="list-inquiry">
        <div class="title">
            <h3>{{$t("inquiry.list")}}</h3>
        </div>
        <div class="body">
            <div class="text-center p-5" v-if="list.data.length === 0" >
                <Empty/>
            </div>
            <div v-else>
                <div v-for="(item, key) in list.data" :key="key">
                    <Item :item="item" />
                </div>
            </div>
        </div>
        <div class="text-center">
            <Pagination
                :length="list.pagination.last"
                v-model="filters.page"
                @input="onPageChange"
                v-if="list.pagination.last > 1"
            />
        </div>
    </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import Empty from './empty.vue'
import Item from './item.vue';
export default {
    name: "List",
    data() {
        return {
            filters: {
                limit: 10,
                page: 1
            }
        };
    },
    async created() {
        this.filters = this.$router.getQuery(this.filters);
        await this.getInquiries({
            ...this.filters,
        });
    },
    watch: {
        $route: {
            deep: true,
            handler() {
                this.filters = this.$router.getQuery(this.filters);
                this.$router.setQuery(this.filters);
                this.getInquiries(this.filters);
            },
        },
    },
    computed: {
        ...mapGetters({
            list: "inquiry/list"
        })
    },
    methods: {
        ...mapActions({
            getInquiries: "inquiry/getInquiries"
        }),
        onPageChange(page) {
            this.filters = {
                ...this.filters,
                page
            }
            this.$router.setQuery(this.filters);
        },
    },
    components: {
    Empty,
    Item
}
}
</script>